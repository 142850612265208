<template>
  <v-row class="modal" justify="center">
    <v-dialog
      content-class="dialog"
      max-width="680"
      min-height="460"
      :value="show"
      @click:outside="close"
    >
      <v-card>
        <div class="top">
          <input
            class="input"
            placeholder="Введите текст..."
            name="text"
            type="text"
            maxlength="40"
            ref="phraseTitle"
            v-model="title"
            @input="touch = true"
          />
        </div>
        <div class="content">
          <div class="left">
            <div class="answers" ref="phraseAnswers">
              <div
                class="answer"
                v-for="(phrase, index) in phrases"
                :key="index"
              >
                <input
                  class="input phrase"
                  placeholder="Введите текст..."
                  name="text"
                  type="text"
                  maxlength="40"
                  v-model="phrases[index]"
                  @input="touch = true"
                  @keypress.enter="submitOnEnter"
                />
                <v-btn
                  color="white"
                  width="37"
                  height="39"
                  min-width="37"
                  depressed
                  @click="deletePhrase(index)"
                  class="deleteBtn"
                >
                  <v-icon medium>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>

            <!-- добавить ответ -->
            <div class="addAnswer">
              <v-btn
                color="white"
                block
                width="40"
                height="49"
                depressed
                @click="addPhrase"
                class="addPhraseBtn"
              >
                <img src="@/assets/add.svg" alt="plus" />
                ОТВЕТ
              </v-btn>
            </div>
          </div>
          <div class="right"></div>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn style="height: 42px" color="black" text @click="close">
            Отмена
          </v-btn>

          <v-btn
            class="submin-btn"
            color="primary"
            :disabled="!valid || !touched"
            @click="submit"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["show", "info"],
  data() {
    return {
      title: "",
      phrases: [],
      touch: false,
      rules: {
        required: (value) => !!value || "Обязательное поле",
        maxSym: (value) => value.length <= 40 || "Слишком много символов",
      },
    };
  },
  methods: {
    addPhrase() {
      this.phrases.push("");
      this.touch = true;
      // фокус на новом инпуте ответа + скролл до него
      setTimeout(() => {
        let phraseInputs = this.$refs.phraseAnswers.getElementsByClassName(
          "input phrase"
        );
        let inputToFocus = phraseInputs[phraseInputs.length - 1];
        inputToFocus.focus();
        inputToFocus.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
    deletePhrase(index) {
      this.phrases.splice(index, 1);
      this.touch = true;
    },
    submitOnEnter() {
      if (this.title.length <= 0) return;
      if (!this.touch) {
        this.addPhrase();
        return;
      }
      this.$emit("submit", {
        title: this.title,
        phrases: this.phrases.filter((phrase) => {
          return phrase.length > 0;
        }),
      });
      this.touch = false;
    },
    submit() {
      if (this.valid) {
        this.$emit("submit", {
          title: this.title,
          phrases: this.phrases,
        });
        this.touch = false;
        this.$emit("closed");
      }
    },
    close() {
      this.$emit("closed");
    },
  },
  computed: {
    valid() {
      let emptyPhrase = this.phrases.findIndex((phrase) => {
        if (phrase.length <= 0) {
          return true;
        }
      });
      return (
        this.title.length >= 1 && this.title.length <= 40 && emptyPhrase === -1
      );
    },
    touched() {
      return this.touch;
    },
  },
  watch: {
    show: function () {
      if (this.show && this.info.title.length <= 0) {
        setTimeout(() => {
          let el = this.$refs.phraseTitle;
          el.focus();
        }, 100);
      }
    },
  },
  created() {
    if (this.info.title) {
      this.title = this.info.title;
    }
    if (this.info.phrases.length > 0) {
      this.phrases = this.info.phrases;
    }
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
}
.dialog {
  .v-card {
    width: 680px !important;
    // min-height: 500px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    padding: 0px 0px 30px 0px;
    &__actions {
      align-self: flex-end;
      padding: 0 !important;
      padding-right: 20px !important;
      margin-top: 17px;
    }
    .subtitle {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #006fc0;
      opacity: 0.8;
      padding: 0;
      margin: 0;
    }
  }
  .top {
    width: 100%;
    border-bottom: 1px solid rgba($color: #1c1c1c, $alpha: 0.1);
    padding: 6px 16px;
  }
  .answers {
    height: 262px;
    width: 100%;
    padding-right: 17px;
    box-sizing: content-box;
    overflow-y: scroll;
  }
  .answer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 30px;
    margin-right: 10px;
    border-radius: 0px 10px 10px 0px;
    &:hover {
      background: #f5f5f5;
    }
    .deleteBtn {
      padding: 0 !important;
      opacity: 0.4;
      &:hover {
        opacity: 1;
      }
    }
  }
  .input {
    width: 200px;
    border-radius: 4px;
    border: 1px solid rgba(28, 28, 28, 0);
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: rgba(28, 28, 28, 0.8);
    padding: 5px 6px;
    background: white;
    &:hover {
      border: 1px solid rgba(28, 28, 28, 0.3);
    }
    &:focus {
      border: 1px solid rgba(0, 111, 192, 0.7);
    }
    &::placeholder {
      color: rgba(28, 28, 28, 0.4);
    }
    &.phrase {
      width: 100%;
    }
  }
  .content {
    height: 318px;
    .left {
      height: 100%;
      width: 257px;
      border-right: 1px solid rgba($color: #1c1c1c, $alpha: 0.1);
      padding: 7px 0px 0 0px;
      overflow: hidden;
      .addAnswer {
        opacity: 0.3;
        &:hover {
          opacity: 1;
        }
        .addPhraseBtn {
          justify-content: flex-start;
        }
      }
    }
    .right {
    }
  }
  .submin-btn {
    height: 42px;
    background: #006fc0 !important;
    &.v-btn--disabled {
      background: rgba(58, 135, 191, 0.7) !important;
      color: rgba(255, 255, 255, 0.8) !important;
    }
  }
}
</style>
