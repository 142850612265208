<template>
  <div class="component" :class="{'notEmpty': blockInfo.text}">
    <span class="text">{{ blockInfo.text ? blockInfo.text : "Введите текст..." }}</span>
    <v-btn
      text
      width="20"
      height="20"
      class="open-modal"
      @click="modalShow = true"
    >
      <v-icon>mdi-arrow-expand-all</v-icon>
    </v-btn>
    <edit-modal
      :show="modalShow"
      :initialText="blockInfo.text"
      @closed="modalShow = false"
      @submit="$emit('submit',$event)"
    />
  </div>
</template>

<script>
import mixinAutoResize from "../autoResize.js";
import editModal from "./modal";
export default {
  props: ["blockInfo"],
  components: { editModal },
  mixins: [mixinAutoResize],
  data() {
    return {
      text: "",
      modalShow: false,
      //   "поднятие стрелки", т.е стрелка начинает следовать за мышью
      rules: {
        required: (value) => !!value || "Обязательное поле",
        maxSym: (value) => value.length <= 1500 || "Слишком много символов",
      },
    };
  },
  computed: {
    wWidth() {
      return window.innerWidth - 17;
    },
    wHeight() {
      // return window.innerHeight - 80;
      return document.body.scrollHeight;
    },
  },
  created() {
    if (this.blockInfo) {
      this.text = this.blockInfo.text;
      // console.log(this.$refs.textarea_for_resize)
    }
  },
};
</script>

<style lang="scss" scoped>
.component {
  cursor: default;
  position: relative;
  background: rgba(240, 243, 244, 0.2);
  border: 1px solid rgba(28, 28, 28, 0.3);
  border-radius: 4px;
  width: 100%;
  // height: 100%;
  display: -webkit-box;
  min-height: 50px;
  padding: 9px 6px;
  .text {
    min-height: 50px;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #1c1c1c;
    opacity: 0.8;
    width: 250px;
    /* OVERFLOW 3 ROWS DOTS   3 строки с точками в конце при оверфлове */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    -ms-line-clamp: 3;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    box-orient: vertical;
  }
  &.notEmpty{
    border: 1px solid rgba(0, 111, 192, 0.2);
  }
}
.open-modal {
  position: absolute;
  right: 0;
  bottom: 0;
  color: rgba(28, 28, 28, 0.3);
  &:hover{
    color: rgba(28, 28, 28, 1);
  }
  &.theme--light.v-btn:hover::before{
    opacity: 0 !important;
  }
  &.v-btn:not(.v-btn--round).v-size--default {
    min-width: 20px !important;
    padding: 0 !important;
  }
}
// сообщ об ошибке
.error-message {
  margin-top: 7px;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.015em;
  color: rgba(218, 29, 29, 0.7) !important;
}
.symbol-count {
  position: absolute;
  font-size: 12px;
  line-height: 22px;
  color: #1c1c1c;
  opacity: 0.6;
  bottom: 0;
  right: 8px;
}
</style>