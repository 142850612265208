<template>
  <v-row justify="center">
    <v-dialog
      content-class="dialog"
      max-width="680"
      min-height="500"
      :value="show"
      @click:outside="close"
    >
      <v-card>
        <p class="subtitle">Текст сообщения</p>
        <v-textarea
          class="input"
          rows="3"
          auto-grow
          color="rgba(0, 111, 192, 0.7)"
          outlined
          placeholder="Введите текст..."
          name="text"
          type="text"
          counter="1500"
          :rules="[rules.required, rules.maxSym]"
          v-model="text"
          @input="touch = true"
        ></v-textarea>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="height: 42px"
            color="black"
            text
            @click="close"
          >
            Отмена
          </v-btn>

          <v-btn
            class="submin-btn"
            color="primary"
            :disabled="!valid || !touched"
            @click="submit"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["show", 'initialText'],
  data() {
    return {
      text: "",

      touch: false,
      rules: {
        required: (value) => !!value || "Обязательное поле",
        maxSym: (value) => value.length <= 1500 || "Слишком много символов",
      },
    };
  },
  methods: {
    submit() {
      if (this.valid) {
        this.$emit("submit", this.text);
        this.touch = false;
        this.$emit('closed')
      }
    },
    close(){
      this.text = this.initialText;
      this.$emit('closed');
    }
  },
  computed:{
    valid(){
      return this.text.length >= 1 && this.text.length <= 1500
    },
    touched(){
      return this.touch
    }
  },
  created() {
    if (this.initialText) {
      this.text = this.initialText;
    }
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .v-card {
    width: 680px !important;
    // min-height: 500px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    padding: 34px 38px 26px 39px;
    &__actions {
      align-self: flex-end;
      padding: 0 !important;
      margin-top: 17px;
    }
    .subtitle {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #006fc0;
      opacity: 0.8;
      padding: 0;
      margin: 0;
    }
  }
  .input {
    width: 100%;
  }
  .submin-btn {
    height: 42px;
    background: #006FC0 !important;
    &.v-btn--disabled {
     background: rgba(58, 135, 191, 0.7) !important;
     color: rgba(255, 255, 255, 0.8) !important;
    }
  }
}
</style>