<template>
  <v-row class="modal" justify="center">
    <v-dialog
      content-class="dialog"
      max-width="680"
      min-height="460"
      :value="show"
      @click:outside="close"
    >
      <v-card>
        <div class="top">
          <div class="title">Post действие</div>
        </div>
        <div class="content">
          <div class="left">
            <div class="feedback-dropdown">
              <div class="subtitle ml-3">Выберите действие</div>
              <v-select
                class="feedback-rounded"
                :items="actions"
                v-model="action"
                filled
                append-icon="mdi-chevron-down"
                dense
                item-text="title"
                item-value="id"
                :menu-props="{ top: false, offsetY: true }"
                @change="touch = true"
                @click="scrollToStart"
              ></v-select>
            </div>

            <div class="key">
              <div class="subtitle">Выберите ключи</div>
              <div class="key__item" v-for="key in keys" :key="key">
                <v-checkbox
                  v-model="selectedKeys"
                  :label="key"
                  :value="key"
                  @click="touch = true"
                ></v-checkbox>
              </div>
            </div>
          </div>
          <div class="right">
            <!-- <textarea
              class="input"
              placeholder="Введите текст..."
              name="text"
              type="text"
              maxlength="1500"
              ref="error_text"
              counter
              no-resize
              v-model="error_text"
              @input="touch = true"
            ></textarea> -->
          </div>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn style="height: 42px" color="black" text @click="close">
            Отмена
          </v-btn>

          <v-btn
            class="submin-btn"
            color="primary"
            :disabled="!valid || !touched"
            @click="submit"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    show: {
      required: true,
    },
    info: { required: true },
    keys: { required: true, default: [] },
    actions: { required: true, default: [] },
  },
  data() {
    return {
      // режим работы модалки
      mode: "create",
      selectedKeys: [],
      action: null,
      touch: false,
      rules: {
        required: (value) => !!value || "Обязательное поле",
        // maxSym: (value) => value.length <= 1500 || "Слишком много символов",
      },
    };
  },
  methods: {
    submit() {
      if (this.valid) {
        this.$emit(this.mode, {
          keys: this.selectedKeys,
          action: this.action,
        });

        this.touch = false;
        this.$emit("closed");
      }
    },

    close() {
      this.$emit("closed");
    },
    // костыль для фикса положения меню селекта в модалке (Баг вьютифай)
    scrollToStart() {
      const scrollX = window.scrollX;
      const scrollY = window.scrollY;
      window.scrollTo(0, 0);
      setTimeout(() => {
        window.scrollTo(scrollX, scrollY);
      }, 10);
    },
  },
  computed: {
    valid() {
      let keysValid = this.selectedKeys.length >= 1;
      let actionValid = this.action !== null;

      return keysValid && actionValid;
    },
    touched() {
      return this.touch;
    },
  },
  watch: {
    // show: function () {
    //   if (this.show && this.info.error_text.length <= 0) {
    //     setTimeout(() => {
    //       let el = this.$refs.error_text;
    //       el.focus();
    //     }, 100);
    //   }
    // },
    show: function () {
      if (this.show) {
        if (this.info == null) {
          this.mode = "create";
        } else {
          this.mode = "edit";
          this.selectedKeys = this.info.keys;
          this.action = this.info.action;
        }
      }
    },
  },
  // created() {

  // },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
}
.dialog {
  .v-card {
    width: 680px !important;
    // min-height: 500px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    padding: 0px 0px 30px 0px;
    &__actions {
      align-self: flex-end;
      padding: 0 !important;
      padding-right: 20px !important;
      margin-top: 17px;
    }
    .subtitle {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: rgba(28, 28, 28, 0.7);
      opacity: 0.8;
    }
  }
  .top {
    width: 100%;
    border-bottom: 1px solid rgba($color: #1c1c1c, $alpha: 0.1);
    padding: 6px 16px;
  }

  .input {
    width: 100%;
    height: 100% !important;
    border-radius: 4px;
    border: 1px solid rgba(28, 28, 28, 0);
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: rgba(28, 28, 28, 0.8);
    padding: 5px 6px;
    background: white;
    // &:hover {
    //   border: 1px solid rgba(28, 28, 28, 0.3);
    // }
    // &:focus {
    //   border: 1px solid rgba(0, 111, 192, 0.7);
    // }
    &::placeholder {
      color: rgba(28, 28, 28, 0.4);
    }
    &.phrase {
      width: 100%;
    }
  }
  .key {
    // max-height: 50px;
    margin-left: 10px;

    &__title {
      font-weight: 500;
      font-size: 12px !important;
      line-height: 22px;
      color: rgba(9, 81, 147, 0.8);
      opacity: 0.8;
      margin: 0;
    }
    .input {
      padding: 0;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .content {
    width: 100%;
    height: 318px;
    display: flex;
    flex-direction: row;
    .left {
      height: 100%;
      width: 420px;
      border-right: 1px solid rgba($color: #1c1c1c, $alpha: 0.1);
      padding: 7px 14px 0 0px;
    }
    .right {
      padding: 7px;
      width: 100%;
      height: 318px;
    }
  }
  .submin-btn {
    height: 42px;
    background: #006fc0 !important;
    &.v-btn--disabled {
      background: rgba(58, 135, 191, 0.7) !important;
      color: rgba(255, 255, 255, 0.8) !important;
    }
  }
}
.feedback-dropdown {
  position: relative;
  width: 300px;
}
</style>
