<template>
  <div
    class="card"
    ref="card"
    v-observe-visibility="{
      callback: onViewport,
      once: true,
    }"
  >
    <div class="card-label" v-if="cardInfo.tag || cardInfo.position.col == 0">
      <span class="text">{{
        cardInfo.position.col == 0 ? "Старт" : cardInfo.tag
      }}</span>
    </div>
    <div
      class="card-content"
      :id="cardInfo.id + '_card'"
      :class="{
        'arrow-hover': arrowGlobal && !arrowFollow,
      }"
      @click="linkedByArrow"
    >
      <div class="d-flex flex-column" v-if="cardVisible">
        <!-- &&
          Math.abs(cardInfo.position.col - arrowInitiatorInfo.col_position) ==
            1, -->
        <div class="card-top">
          <div class="step">
            № {{ cardInfo.position.col + 1 }}.{{ cardIndex }}
          </div>
          <div class="right-side d-flex">
            <div
              class="more-btn_wrapper"
              @mouseenter="cardMenuShow = true"
              @mouseleave="cardMenuShow = false"
            >
              <v-btn
                color="rgb(28, 28, 28, 0.6);"
                width="24"
                height="24"
                class="more-btn"
                text
              >
                <v-icon> mdi-dots-vertical </v-icon>
              </v-btn>
              <!-- меню карточки -->
              <div
                class="cardMenu-wrapper"
                :class="{ visible: cardMenuShow }"
                :style="cardInfo.position.col == 0 || userRole !== 'admin' ? 'right: unset' : null"
              >
                <div class="cardMenu">
                  <v-btn
                    color="white"
                    block
                    width="37"
                    height="39"
                    depressed
                    @click="hashtagAdd"
                    class="cardMenu__btn"
                    v-if="cardInfo.position.col !== 0"
                  >
                    <v-icon medium>mdi-pound</v-icon>
                  </v-btn>
                  <v-btn
                    color="white"
                    block
                    width="37"
                    height="39"
                    depressed
                    class="cardMenu__btn"
                    @click="deleteBlock"
                    v-if="cardInfo.position.col !== 0 && userRole == 'admin'"
                  >
                    <v-icon medium>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                    color="white"
                    block
                    width="37"
                    height="39"
                    depressed
                    class="cardMenu__btn"
                    @click="deleteArrow('block', cardInfo.id)"
                    v-if="userRole == 'admin'"
                  >
                    <v-icon medium>mdi-link-variant-off</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <!-- кружок для стрелки -->
            <div
              class="circle-area"
              @click="userRole == 'admin' ? pickUpArrow() : null"
              @mouseenter="blockHoverOn()"
              @mouseleave="blockHoverOut()"
            >
              <div class="circle" ref="circle">
                <div class="filling" v-if="cardInfo.next_block"></div>
              </div>
              <!-- ${wWidth / zoom} ${wHeight / zoom} -->
              <svg
                class="arrow"
                :viewBox="`${scrollXupdating / zoom} ${
                  scrollYupdating / zoom
                } ${wWidth} ${wHeight}`"
                :style="`width: ${wWidth}px; height: ${wHeight}px; top: ${
                  scrollYupdating / zoom
                }px; left: ${scrollXupdating / zoom}px; `"
                v-if="block_arrowFollow"
                @click="hideArrow"
              >
                <defs>
                  <marker
                    id="markerCircle"
                    markerWidth="5"
                    markerHeight="5"
                    refX="3"
                    refY="3"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="2"
                      style="stroke: none; fill: #006fc0"
                    />
                  </marker>
                  <marker
                    id="arrowhead"
                    markerWidth="4"
                    markerHeight="8"
                    refX="4"
                    refY="4"
                    orient="auto"
                  >
                    <use
                      stroke-linecap="round"
                      xlink:href="#arrow-head"
                      style="stroke-width: 1px"
                    >
                      <path
                        id="arrow-head"
                        fill="#006fc0"
                        stroke="#006fc0"
                        d="M 0 1 L 4 4 M 0 7 L 4 4"
                      />
                    </use>
                  </marker>
                </defs>
                <line
                  :x1="circleX / zoom + circleOnZoomError(zoom)"
                  :y1="circleY / zoom + circleOnZoomError(zoom)"
                  :x2="mouseX / zoom"
                  :y2="mouseY / zoom"
                  stroke="#006fc0"
                  stroke-width="2"
                  marker-end="url(#arrowhead)"
                  marker-start="url(#markerCircle)"
                />
              </svg>
            </div>
          </div>
        </div>
        <!-- hash -->
        <div
          class="hashtag"
          :style="`width: ${
            (cardInfo.tag ? cardInfo.tag.length : 0 + 1) * 8 + 30
          }px`"
          :class="{ hidden: !hashShow }"
        >
          <v-text-field
            class="input"
            dense
            height="23"
            hide-details="auto"
            ref="hashtag"
            v-model="cardInfo.tag"
            @blur="
              blockUpdate(cardInfo.tag, 'tag');
              hashShow = false;
            "
            maxlength="35"
          >
            <v-icon dense size="21" slot="prepend" color="#338CCD">
              mdi-pound
            </v-icon>
          </v-text-field>
        </div>
        <!-- стартовый текстблок -->
        <div class="textarea">
          <p class="title">Текст</p>
          <text-element
            :blockInfo="cardInfo"
            @submit="blockUpdate($event, 'text')"
          />
        </div>
        <!-- добавленные элементы -->
        <div class="addedElements">
          <div class="group" v-show="cardInfo.image_url || toggleAddImage">
            <p class="title" v-show="cardInfo.image_url">Изображение</p>
            <image-element
              :toggleAddImage="toggleAddImage"
              :image_url="cardInfo.image_url"
              ref="image"
              @update="
                blockUpdate($event, 'image_url');
                toggleAddImage = false;
              "
              @close="toggleAddImage = false"
            />
          </div>
          <div class="group">
            <div class="button" v-for="item in cardInfo.buttons" :key="item.id">
              <button-element
                :zoom="zoom"
                :info="item"
                :parent_info="cardInfo"
                @update="updateButton($event)"
                @delete="deleteButton($event, 'button')"
                @arrowFollow="arrowFollow = $event"
                @deleteArrow="deleteArrow('button', $event)"
                @has_next="$emit('has_next', $event)"
                @has_next_or_link="$emit('has_next_or_link')"
              />
            </div>
          </div>
          <div class="group">
            <div
              class="inline"
              v-for="item in cardInfo.inline_buttons"
              :key="item.id"
            >
              <button-element
                :zoom="zoom"
                :type="'inline'"
                :info="item"
                :parent_info="cardInfo"
                @update="updateInline($event)"
                @delete="deleteButton($event, 'inline_button')"
                @deleteArrow="deleteArrow('inline_button', $event)"
                @arrowFollow="arrowFollow = $event"
                @has_next="$emit('has_next', $event)"
                @has_next_or_link="$emit('has_next_or_link')"
              />
            </div>
          </div>
          <!-- текстовый ответ пользователя -->
          <div class="group" v-show="cardInfo.phrases_groups.length > 0">
            <p class="title">Текстовый ответ пользователя</p>
            <div
              class="phrases"
              v-for="item in cardInfo.phrases_groups"
              :key="item.id"
            >
              <button-element
                :zoom="zoom"
                :type="'phrases'"
                :info="item"
                :parent_info="cardInfo"
                @update="updatePhrases($event)"
                @delete="deleteButton($event, 'phrases_group')"
                @deleteArrow="deleteArrow('phrases_group', $event)"
                @arrowFollow="arrowFollow = $event"
                @has_next="$emit('has_next', $event)"
                @has_next_or_link="$emit('has_next_or_link')"
              />
            </div>
          </div>
          <!-- Обратная связь пользователя -->
          <div class="group" v-if="cardInfo.post_data">
            <p class="title">Post данные</p>
            <div class="feedback">
              <button-element
                :zoom="zoom"
                :type="'feedback'"
                :info="cardInfo.post_data"
                :parent_info="cardInfo"
                @update="updateFeedback($event)"
                @delete="deleteButton($event, 'feedback')"
                @deleteArrow="deleteArrow('feedback', $event)"
                @arrowFollow="arrowFollow = $event"
                @has_next="$emit('has_next', $event)"
                @has_next_or_link="$emit('has_next_or_link')"
              />
            </div>
          </div>
          <!-- Обратная связь пользователя -->
          <div class="group" v-if="cardInfo.post_action">
            <p class="title">Post действие</p>
            <div class="action">
              <button-element
                :zoom="zoom"
                :type="'action'"
                :info="cardInfo.post_action"
                :actions="action_actions"
                :parent_info="cardInfo"
                @update="actionModalShow = true"
                @delete="deleteButton($event, 'action')"
              />
            </div>
          </div>
        </div>
        <!-- кнопка добавить для роли admin -->
        <v-menu
          top
          offset-x
          open-on-hover
          nudge-right="5"
          close-delay="300"
          v-if="userRole == 'admin'"
        >
          <template v-slot:activator="{ on, attrs }">
            <button class="btn" v-bind="attrs" v-on="on">
              <v-icon class="btn__icon"> mdi-plus </v-icon
              ><span class="btn__text">Добавить</span>
            </button>
          </template>
          <v-list class="addMenuList" dense>
            <v-list-item-group color="primary"
              ><v-list-item
                v-for="item in addBtnMenu"
                :key="item.id"
                @click="addElement(item.id)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
              <!-- добавить post данные -->
              <v-list-item
                v-if="cardInfo.post_data == null"
                @click="addElement(6)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Post данные</v-list-item-title>
              </v-list-item>
              <!-- добавить post дейсвтие-->
              <v-list-item
                v-if="cardInfo.post_action == null"
                @click="addElement(7)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Post действие</v-list-item-title>
              </v-list-item></v-list-item-group
            >
          </v-list>
        </v-menu>
        <!-- кнопка добавить для роли editor -->
        <v-menu
          top
          offset-x
          open-on-hover
          nudge-right="5"
          close-delay="300"
          v-if="userRole == 'editor'"
        >
          <template v-slot:activator="{ on, attrs }">
            <button class="btn" v-bind="attrs" v-on="on">
              <v-icon class="btn__icon"> mdi-plus </v-icon
              ><span class="btn__text">Добавить</span>
            </button>
          </template>
          <v-list class="addMenuList" dense>
            <v-list-item-group color="primary"
              ><v-list-item @click="addElement(3)">
                <v-list-item-icon>
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Инлайн</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <!-- модалка post actions -->
        <action-modal
          :show="actionModalShow"
          :info="cardInfo.post_action"
          :keys="action_keys"
          :actions="action_actions"
          @create="addPostAction($event)"
          @edit="updatePostAction($event)"
          @closed="actionModalShow = false"
        ></action-modal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ObserveVisibility } from "vue-observe-visibility";
import buttonElement from "@/components/Knowledge/cardElements/_button.vue";
import textElement from "@/components/Knowledge/cardElements/textarea/_textarea.vue";
import imageElement from "@/components/Knowledge/cardElements/_image.vue";
import ActionModal from "./cardElements/actionModal.vue";
export default {
  components: { buttonElement, textElement, imageElement, ActionModal },
  directives: { ObserveVisibility },
  props: {
    zoom: {
      type: Number,
      required: true,
    },
    cardInfo: {
      type: Object,
      required: true,
    },
    cardIndex: {
      type: Number,
      required: true,
    }
  },
  name: "ActionCard",
  data() {
    return {
      cardVisible: false,

      text: "",
      cardMenuShow: false,
      hashShow: false,

      arrowFollow: false,
      block_arrowFollow: false,
      arrowFocused: false,
      // позиция скролла, обновляемая при прокрутке страницы для позиционирования поднятой стрелки
      scrollXupdating: 0,
      scrollYupdating: 0,
      arrowMovement: {
        xChild: 0,
        yChild: 0,
        xParent: 0,
        yParent: 0,
      },
      
action_keys: [],
action_actions: [],

      addBtnMenu: [
        // {
        //   id: 1,
        //   title: "Текст",
        // },
        {
          id: 2,
          title: "Изображение",
        },
        {
          id: 3,
          title: "Инлайн",
        },
        {
          id: 4,
          title: "Кнопка",
        },
        {
          id: 5,
          title: "Ответ пользователя",
        },
      ],
      // показ модалки добавления элемента действия
      actionModalShow: false,

      toggleAddImage: false,
      rules: {
        required: (value) => !!value || "Обязательное поле",
      },
    };
  },
  computed: {
    arrowGlobal() {
      return this.$store.getters["arrow/getArrowGlobalState"];
    },
    arrowInitiatorInfo() {
      return this.$store.getters["arrow/getInitiatorInfo"];
    },
    userRole() {
     return this.$store.getters["authorization/getRole"];
    },
    // allButtonsIds() {
    //   return [
    //     ...this.cardInfo.buttons,
    //     ...this.cardInfo.inline_buttons,
    //     ...this.cardInfo.phrases_groups,
    //   ].map((btn) => {
    //     return btn.id;
    //   });
    // },
    // linkedArrowHovered() {
    //   return this.cardInfo.id == this.hoveredArrowNextBlockId;
    // },
    // размеры экрана пользователя !!
    wWidth() {
      return window.innerWidth / this.zoom - 20;
    },
    wHeight() {
      return window.innerHeight / this.zoom - 80;
    },
    circleX() {
      if (!this.$refs.circle && !this.block_arrowFollow) return 0;
      const scrollX = this.scrollX;
      return this.$refs.circle.getBoundingClientRect().x + 7 + scrollX;
    },
    circleY() {
      if (!this.$refs.circle && !this.block_arrowFollow) return 0;
      const scrollY = this.scrollY;
      return this.$refs.circle.getBoundingClientRect().y - 63 + scrollY;
    },
    mouseX() {
      if (!this.block_arrowFollow) return 0;
      return this.arrowMovement.xParent - 15;
    },
    mouseY() {
      if (!this.block_arrowFollow) return 0;
      return this.arrowMovement.yParent - 70;
    },
    scrollX() {
      if (!this.block_arrowFollow) return 0;
      return window.pageXOffset || document.body.scrollLeft;
    },
    scrollY() {
      if (!this.block_arrowFollow) return 0;
      return window.pageYOffset || document.body.scrollTop;
    },
  },
  methods: {
    // ключи и действи ядля кнопки пост действие
    getActions() {
      return new Promise((resolve, reject) => {
        axios
          .get(`blocks/actions`)
          .then((resp) => {
            this.action_actions = resp.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    getKeys() {
      return new Promise((resolve, reject) => {
        axios
          .get(`blocks/keys`)
          .then((resp) => {
            this.action_keys = resp.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    onViewport(isVisible) {
      if (isVisible) {
        this.cardVisible = true;
        if (this.cardInfo.next_block) {
          this.$emit("has_next", {
            id: this.cardInfo.id,
            circleX: this.circleX - this.scrollX,
            circleY: this.circleY - this.scrollY,
            circle_ref: this.$refs.circle,
            next_block: this.cardInfo.next_block,
          });
        }
      } else {
        if (!this.arrowFollow && !this.block_arrowFollow) {
          this.cardVisible = false;
          this.$emit("has_next", {
            id: this.cardInfo.id,
            circleX: this.circleX,
            circleY: this.circleY,
            circle_ref: this.$refs.circle,
            next_block: null,
          });
        }
      }
    },
    // добавить элемент карточке
    addElement(id) {
      switch (id) {
        case 2: {
          this.toggleAddImage = true;
          setTimeout(() => {
            this.toggleAddImage = false;
          }, 100);
          break;
        }
        case 3: {
          this.addInline();
          break;
        }
        case 4: {
          this.addButton();
          break;
        }
        case 5: {
          this.addPhrasesGroup();
          break;
        }
        case 6: {
          this.addFeedback();
          break;
        }
        case 7: {
          this.actionModalShow = true;
          break;
        }
        default:
          break;
      }
    },
    addButton() {
      return new Promise((resolve, reject) => {
        axios
          .post(`blocks/${this.cardInfo.id}/buttons`, {
            title: "",
            url: null,
            next_block: null,
          })
          .then((resp) => {
            this.$emit("update");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    addInline() {
      return new Promise((resolve, reject) => {
        axios
          .post(`blocks/${this.cardInfo.id}/inline_buttons`, {
            title: "",
            url: null,
            next_block: null,
          })
          .then((resp) => {
            this.$emit("update");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    addPhrasesGroup() {
      return new Promise((resolve, reject) => {
        axios
          .post(`blocks/${this.cardInfo.id}/phrases_groups`, {
            title: "",
            phrases: [],
            next_block: null,
          })
          .then((resp) => {
            this.$emit("update");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    addFeedback() {
      return new Promise((resolve, reject) => {
        axios
          .post(`blocks/${this.cardInfo.id}/post_data`, {
            type: "text",
            error_text: "Введите текст...",
            key: `text_${this.cardInfo.id}`,
            next_block: null,
          })
          .then((resp) => {
            this.$emit("update");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    addPostAction(data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`blocks/${this.cardInfo.id}/post_action`, {
            keys: data.keys,
            action: data.action,
          })
          .then((resp) => {
            this.$emit("update");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    updatePostAction(data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`blocks/${this.cardInfo.id}/post_action`, {
            keys: data.keys,
            action: data.action,
          })
          .then((resp) => {
            this.$emit("update");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    updateFeedback(data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`blocks/${this.cardInfo.id}/post_data`, {
            type: data.type,
            error_text: data.error_text,
            key: data.key,
            next_block: data.next_block,
          })
          .then((resp) => {
            this.$emit("update");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    updateInline(data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`blocks/${this.cardInfo.id}/inline_buttons/${data.id}`, {
            title: data.title,
            url: data.url == null || data.url.length <= 0 ? null : data.url,
          })
          .then((resp) => {
            this.$emit("update");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    updateButton(data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`blocks/${this.cardInfo.id}/buttons/${data.id}`, {
            title: data.title,
            url: data.url == null || data.url.length <= 0 ? null : data.url,
          })
          .then((resp) => {
            this.$emit("update");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    updatePhrases(data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`blocks/${this.cardInfo.id}/phrases_groups/${data.id}`, {
            title: data.title,
            phrases: data.phrases,
          })
          .then((resp) => {
            this.$emit("update");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    deleteButton(id, element) {
      let urlPath =
        element == "feedback"
          ? `blocks/${this.cardInfo.id}/post_data`
          : element == "action"
          ? `blocks/${this.cardInfo.id}/post_action`
          : `blocks/${this.cardInfo.id}/${element}s/${id}`;
      return new Promise((resolve, reject) => {
        axios
          .delete(urlPath)
          .then((resp) => {
            this.$emit("update");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    deleteArrow(element, id) {
      let urlPath =
        element == "block"
          ? `blocks/${id}/link`
          : element == "feedback"
          ? `blocks/${this.cardInfo.id}/post_data/link`
          : `blocks/${this.cardInfo.id}/${element}s/${id}/link`;
      return new Promise((resolve, reject) => {
        axios
          .delete(urlPath)
          .then((resp) => {
            this.$emit("update");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    blockUpdate(data, field_name) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`blocks/${this.cardInfo.id}`, {
            tag: field_name == "tag" ? data : this.cardInfo.tag,
            text: field_name == "text" ? data : this.cardInfo.text,
            buttons: this.cardInfo.buttons,
            inline_buttons: this.cardInfo.inline_buttons,
            position: this.cardInfo.position,
            image_url:
              field_name == "image_url" ? data : this.cardInfo.image_url,
          })
          .then((resp) => {
            this.$emit("update");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    deleteBlock() {
      return new Promise((resolve, reject) => {
        axios
          .delete(`blocks/${this.cardInfo.id}`)
          .then((resp) => {
            this.$emit("deleted", this.cardInfo.id);
            this.cardMenuShow = false;
            resolve(resp);
          })
          .catch((err) => {
            this.cardMenuShow = false;
            reject(err);
            console.log(err);
          });
      });
    },
    linkedByArrow() {
      // &&
      // Math.abs(
      //   this.cardInfo.position.col - this.arrowInitiatorInfo.col_position
      // ) == 1
      if (this.arrowGlobal && !this.arrowFollow && !this.block_arrowFollow) {
        this.$emit("linked", this.cardInfo.id);
      }
    },
    hashtagAdd() {
      this.hashShow = true;
      let x = window.scrollX,
        y = window.scrollY;
      this.$refs.hashtag.focus();
      window.scrollTo(x, y);
    },
    documentClick(e) {
      if (!this.$el.contains(e.target)) {
        if (this.block_arrowFollow) {
          this.hideArrow();
        }

        // отключить редактирование кнопки при клике вне
        if (this.arrowFocused) {
          this.arrowFocused = false;
        }
      }
    },
    // возврат погрешности положения начала стрелки при зуме
    circleOnZoomError(zoom) {
      let zoomDecimal = Math.round(zoom * 100) / 100;
      if (zoomDecimal <= 0.1) {
        return -30.5;
      } else if (zoomDecimal <= 0.2) {
        return -25.5;
      } else if (zoomDecimal <= 0.3) {
        return -13.5;
      } else if (zoomDecimal <= 0.4) {
        return -10.5;
      } else if (zoomDecimal <= 0.5) {
        return -6.5;
      } else if (zoomDecimal <= 0.6) {
        return -4.5;
      } else if (zoomDecimal <= 0.7) {
        return -3.0;
      } else if (zoomDecimal <= 0.8) {
        return -1.5;
      } else if (zoomDecimal <= 0.9) {
        return -0.5;
      } else if (zoomDecimal >= 1.8) {
        return 3.5;
      } else if (zoomDecimal >= 1.6) {
        return 2.5;
      } else if (zoomDecimal >= 1.4) {
        return 2;
      } else if (zoomDecimal >= 1.2) {
        return 1.5;
      } else if (zoomDecimal >= 1.1) {
        return 1;
      } else {
        return 0;
      }
    },
    pickUpArrow() {
      if (!this.cardInfo.next_block) {
        this.block_arrowFollow = true;
        this.$emit("arrowFollow", true);
        if (!this.$store.getters["arrow/getArrowGlobalState"]) {
          this.$store.dispatch("arrow/activateArrow");
        }
        this.$store.dispatch("arrow/saveBlockInfo", {
          block_id: this.cardInfo.id,
          element_id: this.cardInfo.id,
          type: "block",
          col_position: this.cardInfo.position.col,
          backward: true,
        });
        this.arrowMovement = {
          xParent: this.circleX,
          yParent: this.circleY + 63,
        };
        // следить за скроллом
        window.addEventListener("scroll", this.handleScroll);
        // изначально определить положение скролла без прокрутки
        this.handleScroll();
        // поднимаем временно zIndex колонки и ячейку с карточкой, чтобы стрелка была поверх всех карточек
        document
          .getElementById(this.cardInfo.position.col + 1 + "_column")
          .classList.add("onTop");
        document
          .getElementById(this.cardInfo.position.col + 1 + "_row")
          .classList.add("onTop");
      } else {
        this.$emit("has_next_or_link");
      }
    },
    hideArrow() {
      this.block_arrowFollow = false;
      if (this.$store.getters["arrow/getArrowGlobalState"]) {
        this.$store.dispatch("arrow/disableArrow");
      }
      window.removeEventListener("scroll", this.handleScroll);
      // убираем zIndex колонки
      document
        .getElementById(this.cardInfo.position.col + 1 + "_column")
        .classList.remove("onTop");
      document
        .getElementById(this.cardInfo.position.col + 1 + "_row")
        .classList.remove("onTop");
    },
    handleScroll() {
      this.scrollXupdating = window.pageXOffset || document.body.scrollLeft;
      this.scrollYupdating = window.pageYOffset || document.body.scrollTop;
    },
    moveCursor(e) {
      if (this.block_arrowFollow) {
        this.arrowMovement.xChild = e.clientX;
        this.arrowMovement.yChild = e.clientY;
        setTimeout(() => {
          this.arrowMovement.xParent =
            e.clientX +
            (document.documentElement.scrollLeft
              ? document.documentElement.scrollLeft
              : document.body.scrollLeft);
          this.arrowMovement.yParent =
            e.clientY +
            (document.documentElement.scrollTop
              ? document.documentElement.scrollTop
              : document.body.scrollTop);
        }, 100);
      }
    },
    blockHoverOn() {
      if (!this.cardInfo.next_block) return;
      document
        .getElementById(this.cardInfo.id + "_arrow")
        .classList.add("highlight");
      document
        .getElementById(this.cardInfo.next_block + "_card")
        .classList.add("highlight");
      document
        .getElementById(this.cardInfo.id + "_card")
        .classList.add("highlight");
    },
    blockHoverOut() {
      if (!this.cardInfo.next_block) return;
      document
        .getElementById(this.cardInfo.id + "_arrow")
        .classList.remove("highlight");

      document
        .getElementById(this.cardInfo.next_block + "_card")
        .classList.remove("highlight");
      document
        .getElementById(this.cardInfo.id + "_card")
        .classList.remove("highlight");
    },
  },
  watch: {
    // вместо создания прослушивания ивента при mount, делаем через watch, чтобы снизить потребление памяти (возможно)
    block_arrowFollow: function () {
      if (this.block_arrowFollow) {
        document.addEventListener("click", this.documentClick);
        document.addEventListener("mousemove", this.moveCursor);
      } else {
        document.removeEventListener("click", this.documentClick);
        document.removeEventListener("mousemove", this.moveCursor);
      }
    },
    arrowFocused: function () {
      if (this.arrowFocused) {
        document.addEventListener("click", this.documentClick);
      } else {
        document.removeEventListener("click", this.documentClick);
      }
    },
    "cardInfo.next_block": function () {
      if (this.cardInfo.next_block) {
        this.$emit("has_next", {
          id: this.cardInfo.id,
          circleX: this.circleX - this.scrollX,
          circleY: this.circleY - this.scrollY,
          circle_ref: this.$refs.circle,
          next_block: this.cardInfo.next_block,
        });
      } else {
        this.$emit("has_next", {
          id: this.cardInfo.id,
          circleX: this.circleX,
          circleY: this.circleY,
          circle_ref: this.$refs.circle,
          next_block: null,
        });
      }
    },
  },
  mounted() {
    if (this.cardInfo.next_block) {
      this.$emit("has_next", {
        id: this.cardInfo.id,
        circleX: this.circleX - this.scrollX,
        circleY: this.circleY - this.scrollY,
        circle_ref: this.$refs.circle,
        next_block: this.cardInfo.next_block,
      });
    }

    // let element = this.$refs.card;
    // let elementWatcher = this.$scrollmonitor.create(element);
    // elementWatcher.enterViewport(function () {
    //   element.classList.remove("hidden");
    // });
    // elementWatcher.exitViewport(function () {
    //   element.classList.add("hidden");
    // });
  },
  created(){
    this.getKeys()
    this.getActions()
  }
  // beforeDestroy() {
  // document.removeEventListener("click", this.documentClick);
  //  this.$emit("has_next", {
  //           id: this.cardInfo.id,
  //           circleX: this.circleX,
  //           circleY: this.circleY,
  //           circle_ref: this.$refs.circle,
  //           next_block: null,
  //         });
  // },
};
</script>

<style lang="scss" scoped>
.card {
  width: 300px;
  position: relative;
  pointer-events: all;
  // z-index: 15;
  padding-top: 27px;
  &.hidden {
    display: none;
  }
}
.card-label {
  background: #2097f7;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px 5px 0px 0px;
  padding: 2px 23px 20px 23px;
  display: inline-block;
  position: absolute;
  z-index: -1;
  top: 0;
  max-width: 300px;
  .text {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    word-break: break-all;
    white-space: nowrap;
  }
}
.card-content {
  padding: 10px 10px 20px 10px;
  background: #ffffff;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 9px 10px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  width: 300px;
  // z-index: 15;
  display: flex;
  flex-direction: column;
  border: 2px solid transparent;
  transition: border 0.3s ease;
  &.arrow-hover {
    cursor: pointer;
    &:hover {
      border: 2px solid #88b3e4;
      box-shadow: 0px 5px 20px rgba(132, 182, 240, 0.3),
        0px 5px 5px rgba(0, 0, 0, 0.2), 0px 9px 10px rgba(0, 0, 0, 0.14);
    }
  }
  &.highlight {
    border: 2px solid #88b3e4;
    box-shadow: 0px 5px 20px rgba(132, 182, 240, 0.3),
      0px 5px 5px rgba(0, 0, 0, 0.2), 0px 9px 10px rgba(0, 0, 0, 0.14);
  }
  &.focus {
    border: 2px solid #88b3e4;
    box-shadow: 0px 5px 20px rgba(132, 182, 240, 0.3),
      0px 5px 5px rgba(0, 0, 0, 0.2), 0px 9px 10px rgba(0, 0, 0, 0.14);
  }
  .card-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .step {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: #006fc0;
      opacity: 0.8;
      margin-bottom: 15px;
    }
    .more-btn {
      opacity: 0.8;
      min-width: 24px !important;
      padding: 0 !important;
      &:hover {
        opacity: 1;
      }
    }
  }

  .btn {
    width: 146px;
    height: 36px;
    align-self: center;
    margin-top: 20px;
    outline: none;
    background: transparent;
    opacity: 0.3;
    border: 1px solid #1c1c1c;
    box-sizing: border-box;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #1c1c1c;
    transition: all 0.3s ease;
    &__icon {
      color: inherit;
    }
    &:hover {
      color: #006fc0;
      opacity: 1;
      border: 1px solid #006fc0;
    }
  }
}
p.title {
  font-weight: 500;
  font-size: 12px !important;
  line-height: 22px;
  color: rgba(9, 81, 147, 0.8);
  opacity: 0.8;
  margin: 0;
}
.textarea {
  margin-bottom: 14px;
}
.btn {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
  //   opacity: 0.3;
  //   transition: all 0.3s;
  //   &:hover{
  //       color: #006FC0 !important;
  //   }
  //   &:focus{
  //     opacity: 1;
  //   }
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 36px;
    margin-left: 4px;
  }
  //   &.theme--light.v-btn:hover::before {
  //     opacity: 0 !important;
  //   }
}
.addMenuList {
  .v-list-item__icon:first-child {
    margin-right: 6px;
  }
}
.addedElements {
  .group {
    margin-bottom: 14px;
    .image {
      border: 1px solid rgba(0, 111, 192, 0.2);
      border-radius: 4px;
    }
  }
  .button,
  .inline,
  .phrases {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}
.cardMenu {
  background: white;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  &__btn {
    opacity: 0.4;
    min-width: 37px !important;
    padding: 0 !important;
  }
  &-wrapper {
    padding-bottom: 16px;
    top: -16px;
    right: 0px;
    position: absolute;
    transition: visibility 0s ease, opacity 0.3s ease;
    visibility: hidden;
    opacity: 0;
    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }
}
.hashtag {
  min-width: 143px;
  width: 143px;
  max-width: 264px;
  margin-bottom: 20px;
  &.hidden {
    height: 0;
    position: fixed;
    opacity: 0;
    top: -100%;
    z-index: -100;
  }
  .input {
    padding: 0 !important;
    margin: 0 !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: rgba(28, 28, 28, 0.7);
  }
}
.circle {
  &-area {
    padding-left: 14px;
    position: relative;
    top: 0px;
    right: 0px;
    cursor: pointer;
  }

  width: 14px;
  height: 14px;
  border-radius: 100%;
  border: 1px solid rgba($color: #006fc0, $alpha: 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  .filling {
    background: rgba($color: #006fc0, $alpha: 0.7);
    width: 8px;
    height: 8px;
    border-radius: 100%;
    transition: background 0.3s ease-in-out;
    &.active {
      background: #006fc0;
    }
  }
}
.arrow {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  #arrowhead {
    stroke: transparent;
    fill: #006fc0;
  }
}
</style>
