<template>
  <div
    class="container"
    @mouseenter="btnMenuOpened = true"
    @mouseleave="btnMenuOpened = false"
  >
    <img v-if="image_url" :src="image_url" alt="card_img" class="image" />
    <!-- кнопки редакт, удалить -->
    <div class="btnMenu-wrapper" :class="{ visible: btnMenuOpened }">
      <div class="btnMenu">
        <v-btn
          color="white"
          block
          width="37"
          height="39"
          depressed
          @click="addFile"
          class="btnMenu__btn"
        >
          <v-icon medium>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          color="white"
          block
          width="37"
          height="39"
          depressed
          class="btnMenu__btn"
          @click="deleteImage"
        >
          <v-icon medium>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>
    <input
      class="image-input"
      type="file"
      ref="file"
      v-on:change="handleImageUpload()"
      accept="image/*"
    />
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["image_url", "toggleAddImage"],
  data() {
    return {
      btnMenuOpened: false,
      url: "",
      file: {
        ref: "",
        name: "",
      },
    };
  },
  methods: {
    addFile() {
      this.$refs.file.value = "";
      this.$refs.file.click();
    },
    handleImageUpload() {
      this.file.ref = this.$refs.file.files[0];
      this.file.name = this.$refs.file.files[0].name;
      this.$refs.file.value = "";
      this.uploadImage();
    },
    uploadImage() {
      let file = this.file.ref;
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post(`/blocks/upload_image`, formData)
        .then((resp) => {
          this.url = "";
          this.url = resp.data.url;
          this.$emit("update", this.url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteImage() {
      this.$emit("update", null);
    },
  },
  watch: {
    toggleAddImage: function() {
      if (this.toggleAddImage) {
        this.addFile();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
  position: relative;
}
.image {
  border: 1px solid rgba(0, 111, 192, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 186px;
  object-fit: cover;
}
.image-input {
  position: fixed;
  opacity: 0;
  top: -100%;
}
.btnMenu {
  background: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 9px 10px rgba(0, 0, 0, 0.14),
    0px 5px 14px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  &-wrapper {
    transition: visibility 0s ease, opacity 0.3s ease;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 3px;
    right: -44px;
    padding: 4px;
    z-index: 9999;
    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  &__btn {
    opacity: 0.4;
  }
}
</style>
