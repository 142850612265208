<template>
  <div
    class="button-element"
    @mouseenter="
      btnMenuOpened || focused || arrowFollow   ? null : (btnMenuOpened = true);
      buttonHoverOn(info.id, info.next_block);
    "
    @mouseleave="
      btnMenuOpened = false;
      buttonHoverOut(info.id, info.next_block);
    "
  >
    <!-- инпут -->
    <div
      class="input-wrapper"
      :id="info.id + '_button'"
      :class="{
        focus: focused,
        error: inputError,
        inline: type == 'inline',
        phrases: type === 'phrases',
        feedback: type == 'feedback',
        action: type == 'action',
      }"
    >
      <!-- обычный текст или инпут в фокусе -->
      <div
        class="text textarea"
        v-if="
          !focused &&
          type !== 'phrases' &&
          type !== 'feedback' &&
          type !== 'action'
        "
      >
        {{ text ? text : "Введите текст..." }}
      </div>
      <!-- для ответа пользователя исп title -->
      <div
        class="text textarea"
        v-if="type === 'phrases' || type === 'feedback' || type == 'action'"
      >
        {{
          info.title
            ? info.title
            : info.error_text
            ? info.error_text
            : info.action
            ? getActionTitle(info.action)
            : "Введите текст..."
        }}
      </div>
      <textarea
        class="input textarea"
        ref="resize"
        type="text"
        :class="{ hidden: !focused }"
        placeholder="Введите текст..."
        v-model="text"
        maxlength="40"
        @input="mixin_autoResize_resize"
        @click="mixin_autoResize_resize"
        @blur="updateText"
      />
      <div class="link" v-if="focused || info.url">
        <v-icon class="icon" :class="{ focus: linkFocused }">mdi-link</v-icon>
        <input
          @focus="linkFocused = true"
          @blur="updateUrl"
          class="input"
          placeholder="Введите ссылку..."
          type="text"
          v-model="url"
        />
      </div>
      <!-- дропдаун типов для обратной связи -->
      <div class="feedback-dropdown" v-if="type === 'feedback'">
        <v-select
          :items="feedbackTypes"
          v-model="info.type"
          hide-details
          append-icon="mdi-chevron-down"
          dense
          filled
          :menu-props="{ top: false, offsetY: true }"
          :disabled="userRole == 'editor'"
          @change="updateFeedback()"
        ></v-select>
      </div>
    </div>
    <!-- колво сиволов -->
    <div
      class="symbol-count"
      :class="{ inline: type == 'inline' }"
      v-if="focused"
    >
      {{ text.length }}/40
    </div>
    <!-- сообще оь ошибке -->
    <div
      class="error-message"
      v-if="!rules.required(text) || !rules.maxSym(text)"
    >
      {{ rules.required(text) || rules.maxSym(text) }}
    </div>
    <!-- кружок для стрелки -->
    <div class="circle-area" @click="userRole == 'admin' ? pickUpArrow() : null" v-if="type !== 'action'">
      <div
        class="circle"
        :class="{
          inline: type == 'inline',
          phrases: type == 'phrases',
          feedback: type == 'feedback',
        }"
        ref="circle"
      >
        <div class="filling" v-if="hasNextBlock"></div>
      </div>
      <!-- ${wWidth / zoom} ${wHeight / zoom} -->
      <svg
        class="arrow"
        :viewBox="`${scrollXupdating / zoom} ${
          scrollYupdating / zoom
        } ${wWidth} ${wHeight}`"
        :style="`width: ${wWidth}px; height: ${wHeight}px; top: ${
          scrollYupdating / zoom
        }px; left: ${scrollXupdating / zoom}px; `"
        v-if="arrowFollow"
        @click="hideArrow"
      >
        <defs>
          <marker
            id="markerCircle"
            markerWidth="5"
            markerHeight="5"
            refX="3"
            refY="3"
          >
            <circle cx="3" cy="3" r="2" style="stroke: none; fill: #006fc0" />
          </marker>
          <marker
            id="arrowhead"
            markerWidth="4"
            markerHeight="8"
            refX="4"
            refY="4"
            orient="auto"
          >
            <use
              stroke-linecap="round"
              xlink:href="#arrow-head"
              style="stroke-width: 1px"
            >
              <path
                id="arrow-head"
                fill="#006fc0"
                stroke="#006fc0"
                d="M 0 1 L 4 4 M 0 7 L 4 4"
              />
            </use>
          </marker>
        </defs>
        <line
          :x1="circleX / zoom + circleOnZoomError(zoom)"
          :y1="circleY / zoom + circleOnZoomError(zoom)"
          :x2="mouseX / zoom"
          :y2="mouseY / zoom"
          stroke="#006fc0"
          stroke-width="2"
          marker-end="url(#arrowhead)"
          marker-start="url(#markerCircle)"
        />
        <!-- <path
            fill="transparent"
            stroke="#006fc0"
            style="stroke-width: 2px"
            :d="
              `M ${circleX / zoom} ${circleY / zoom} 
            C ${circleX / zoom + 100} ${circleY / zoom}  
              ${mouseX / zoom - 100} ${mouseY / zoom}
             ${mouseX / zoom}, ${mouseY / zoom}`
            "
            marker-end="url(#arrowhead)"
            marker-start="url(#markerCircle)"
          /> -->
      </svg>
    </div>
    <!-- кнопки редакт, удалить -->
    <div class="btnMenu-wrapper" :class="{ visible: btnMenuOpened }">
      <div class="btnMenu">
        <v-btn
          color="white"
          block
          width="37"
          height="39"
          depressed
          v-if="!(type == 'action' && userRole == 'editor')"
          @click="
            type == 'phrases'
              ? phrasesEdit()
              : type == 'feedback'
              ? feedbackEdit()
              : type == 'action'
              ? actionEdit()
              : btnEdit()
          "
          class="btnMenu__btn"
        >
          <v-icon medium>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          color="white"
          block
          width="37"
          height="39"
          depressed
          v-if="userRole == 'admin'"
          @click="btnDelete"
          class="btnMenu__btn"
        >
          <v-icon medium>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          color="white"
          block
          width="37"
          height="39"
          depressed
          v-if="userRole == 'admin'"
          @click="btnDeleteArrow"
          class="btnMenu__btn"
          v-show="info.next_block"
        >
          <v-icon medium>mdi-link-variant-off</v-icon>
        </v-btn>
        <phrases-modal
          v-if="type === 'phrases'"
          :show="phrasesModalShow"
          :info="info"
          @closed="phrasesModalShow = false"
          @submit="updatePhrases($event)"
        />
        <feedback-modal
          v-if="type === 'feedback'"
          :show="feedbackModalShow"
          :info="info"
          @closed="feedbackModalShow = false"
          @submit="updateFeedback($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mixinAutoResize from "./autoResize.js";
import phrasesModal from "./phrasesModal";
import feedbackModal from "./feedbackModal";
export default {
  components: { phrasesModal, feedbackModal },
  props: {
    parent_info: { type: Object, required: true },
    info: { type: Object, required: true },
    zoom: { type: Number, required: true },
    type: { type: String, required: false },
    // спискок действией блока для пост действий кнопки
    actions: { type: Array, required: false },
  },
  mixins: [mixinAutoResize],
  name: "ActionCard-button",
  data() {
    return {
      text: "",
      url: "",
      // открыто редактирование кнопки
      focused: false,
      btnMenuOpened: false,
      inputError: false,
      linkFocused: false,
      // модалка редактирования групп ответов пользователей
      phrasesModalShow: false,
      // модалка редактирования обратной связи
      feedbackModalShow: false,
      feedbackTypes: [
        { value: "text", text: "Текст" },
        { value: "photo", text: "Фото" },
        { value: "file", text: "Файл" },
        { value: "number", text: "Число" },
        { value: "email", text: "Почта" },
        { value: "phone", text: "Телефон" },
      ],
      // если есть привязка уже, стрелку не поднимаем
      hasNextBlock: false,
      //   "поднятие стрелки", т.е стрелка начинает следовать за мышью
      arrowFollow: false,
      arrowMovement: {
        xChild: 0,
        yChild: 0,
        xParent: 0,
        yParent: 0,
      },
      // позиция скролла, обновляемая при прокрутке страницы для позиционирования поднятой стрелки
      scrollXupdating: 0,
      scrollYupdating: 0,
      rules: {
        required: (value) => !!value || "Обязательное поле",
        maxSym: (value) => value.length <= 40 || "Слишком много символов",
      },
    };
  },
  methods: {
    moveCursor(e) {
      if (this.arrowFollow) {
        this.arrowMovement.xChild = e.clientX;
        this.arrowMovement.yChild = e.clientY;
        setTimeout(() => {
          this.arrowMovement.xParent =
            e.clientX +
            (document.documentElement.scrollLeft
              ? document.documentElement.scrollLeft
              : document.body.scrollLeft);
          this.arrowMovement.yParent =
            e.clientY +
            (document.documentElement.scrollTop
              ? document.documentElement.scrollTop
              : document.body.scrollTop);
        }, 100);
      }
    },
    handleScroll() {
      this.scrollXupdating = window.pageXOffset || document.body.scrollLeft;
      this.scrollYupdating = window.pageYOffset || document.body.scrollTop;
    },
    pickUpArrow() {
      if (!this.hasNextBlock && !this.info.url) {
        this.arrowFollow = true;
        this.$emit("arrowFollow", true);
        if (!this.$store.getters["arrow/getArrowGlobalState"]) {
          this.$store.dispatch("arrow/activateArrow");
        }
        this.$store.dispatch("arrow/saveBlockInfo", {
          block_id: this.parent_info.id,
          element_id: this.info.id,
          type:
            this.type == "inline"
              ? "inline_button"
              : this.type == "phrases"
              ? "phrases_group"
              : this.type == "feedback"
              ? "feedback"
              : "button",
          col_position: this.parent_info.position.col,
          backward: true,
        });
        this.arrowMovement = {
          xParent: this.circleX,
          yParent: this.circleY + 63,
        };
        // следить за скроллом
        window.addEventListener("scroll", this.handleScroll);
        // изначально определить положение скролла без прокрутки
        this.handleScroll();
        // поднимаем временно zIndex колонки и ячейку с карточкой, чтобы стрелка была поверх всех карточек
        document
          .getElementById(this.parent_info.position.col + 1 + "_column")
          .classList.add("onTop");
        document
          .getElementById(this.parent_info.position.col + 1 + "_row")
          .classList.add("onTop");
      } else {
        this.$emit("has_next_or_link");
      }
    },
    hideArrow() {
      this.arrowFollow = false;
      this.$emit("arrowFollow", false);
      if (this.$store.getters["arrow/getArrowGlobalState"]) {
        this.$store.dispatch("arrow/disableArrow");
      }
      window.removeEventListener("scroll", this.handleScroll);
      // убираем zIndex колонки и ячейку с карточкой
      document
        .getElementById(this.parent_info.position.col + 1 + "_column")
        .classList.remove("onTop");
      document
        .getElementById(this.parent_info.position.col + 1 + "_row")
        .classList.remove("onTop");
    },
    checkUrl(url) {
      let reg = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i;
      return url !== null ? url.match(reg) : false;
    },
    updateText() {
      this.$emit("update", {
        id: this.info.id,
        title: this.text,
        url: this.info.url,
      });
    },
    updateUrl() {
      if (this.info.next_block) {
        this.$emit("has_next_or_link");
        this.url = "";
      } else if (
        this.checkUrl(this.info.url) ||
        this.info.url == null ||
        this.info.url.length == 0
      ) {
        this.$emit("update", {
          id: this.info.id,
          title: this.info.title,
          url: this.url,
        });
        this.linkFocused = false;
      }
    },
    updatePhrases(data) {
      this.$emit("update", {
        id: this.info.id,
        title: data.title,
        phrases: data.phrases,
      });
    },
    updateFeedback(data) {
      if (!data) {
        this.$emit("update", {
          type: this.info.type,
          error_text: this.info.error_text,
          key: this.info.key,
          next_block: this.info.next_block,
        });
      } else {
        this.$emit("update", {
          type: data.type,
          error_text: data.error_text,
          key: data.key,
          next_block: data.next_block,
        });
      }
    },
    documentClick(e) {
      if (!this.$el.contains(e.target)) {
        if (this.arrowFollow) {
          this.hideArrow();
        }

        // отключить редактирование кнопки при клике вне
        if (this.focused) {
          this.focused = false;
        }
      }
    },
    phrasesEdit() {
      this.phrasesModalShow = true;
    },
    feedbackEdit() {
      this.feedbackModalShow = true;
    },
    actionEdit() {
      this.$emit('update');
    },
    btnEdit() {
      this.focused = true;
      this.btnMenuOpened = false;
      let x = window.scrollX,
        y = window.scrollY;
      this.$refs.resize.focus();
      window.scrollTo(x, y);
      // this.$refs.resize.style.height = `auto`;
      // this.$refs.resize.style.height = `${this.$refs.resize.scrollHeight}px`;
    },
    btnDelete() {
      this.$emit("delete", this.info.id);
    },
    btnDeleteArrow() {
      this.$emit("deleteArrow", this.info.id);
    },
    // возврат погрешности положения начала стрелки при зуме
    circleOnZoomError(zoom) {
      let zoomDecimal = Math.round(zoom * 100) / 100;
      if (zoomDecimal <= 0.1) {
        return -30.5;
      } else if (zoomDecimal <= 0.2) {
        return -25.5;
      } else if (zoomDecimal <= 0.3) {
        return -13.5;
      } else if (zoomDecimal <= 0.4) {
        return -10.5;
      } else if (zoomDecimal <= 0.5) {
        return -6.5;
      } else if (zoomDecimal <= 0.6) {
        return -4.5;
      } else if (zoomDecimal <= 0.7) {
        return -3.0;
      } else if (zoomDecimal <= 0.8) {
        return -1.5;
      } else if (zoomDecimal <= 0.9) {
        return -0.5;
      } else if (zoomDecimal >= 1.8) {
        return 3.5;
      } else if (zoomDecimal >= 1.6) {
        return 2.5;
      } else if (zoomDecimal >= 1.4) {
        return 2;
      } else if (zoomDecimal >= 1.2) {
        return 1.5;
      } else if (zoomDecimal >= 1.1) {
        return 1;
      } else {
        return 0;
      }
    },

    buttonHoverOn(arrow_id, next_block_id) {
      if (!next_block_id) return;
      document.getElementById(arrow_id + "_arrow").classList.add("highlight");

      document
        .getElementById(next_block_id + "_card")
        .classList.add("highlight");
    },
    buttonHoverOut(arrow_id, next_block_id) {
      if (!next_block_id) return;
      document
        .getElementById(arrow_id + "_arrow")
        .classList.remove("highlight");

      document
        .getElementById(next_block_id + "_card")
        .classList.remove("highlight");
    },
    getActionTitle(action) {
      let ret = '';
      this.actions.forEach((item) => {
        if (item.id == action) ret = item.title;
      });
      return ret
    },
  },
  computed: {
    
    // размеры экрана пользователя !!
    wWidth() {
      return window.innerWidth / this.zoom - 20;
    },
    wHeight() {
      return window.innerHeight / this.zoom - 80;
    },
    circleX() {
      if (!this.$refs.circle && !this.arrowFollow) return 0;
      const scrollX = this.scrollX;
      return this.$refs.circle.getBoundingClientRect().x + 7 + scrollX;
    },
    circleY() {
      if (!this.$refs.circle && !this.arrowFollow) return 0;
      const scrollY = this.scrollY;
      return this.$refs.circle.getBoundingClientRect().y - 63 + scrollY;
    },
    mouseX() {
      if (!this.arrowFollow) return 0;
      return this.arrowMovement.xParent - 15;
    },
    mouseY() {
      if (!this.arrowFollow) return 0;
      return this.arrowMovement.yParent - 70;
    },
    scrollX() {
      if (!this.arrowFollow) return 0;
      return window.pageXOffset || document.body.scrollLeft;
    },
    scrollY() {
      if (!this.arrowFollow) return 0;
      return window.pageYOffset || document.body.scrollTop;
    },
    userRole() {
     return this.$store.getters["authorization/getRole"];
    },
  },
  created() {
    if (this.info.title) {
      this.text = this.info.title;
      // console.log(this.$refs.textarea_for_resize)
    }
    if (this.info.url) {
      this.url = this.info.url;
    }
  },
  mounted() {
    // document.addEventListener("click", this.documentClick);
    // this.drawArrow()
    document.addEventListener("mousemove", this.moveCursor);
    // document.addEventListener("mouseleave", (e) => {
    //   this.hideCursor = true;
    // });
    // document.addEventListener("mouseenter", (e) => {
    //   this.hideCursor = false;
    // });
    if (this.info.next_block) {
      this.$emit("has_next", {
        id: this.info.id,
        circleX: this.circleX - this.scrollX,
        circleY: this.circleY - this.scrollY,
        circle_ref: this.$refs.circle,
        next_block: this.info.next_block,
      });
      this.hasNextBlock = true;
    }
  },
  watch: {
    "info.next_block": function () {
      if (this.info.next_block) {
        this.$emit("has_next", {
          id: this.info.id,
          circleX: this.circleX - this.scrollX,
          circleY: this.circleY - this.scrollY,
          circle_ref: this.$refs.circle,
          next_block: this.info.next_block,
        });
        this.hasNextBlock = true;
      } else {
        this.$emit("has_next", {
          id: this.info.id,
          circleX: this.circleX,
          circleY: this.circleY,
          circle_ref: this.$refs.circle,
          next_block: null,
        });
        this.hasNextBlock = false;
      }
    },
    // вместо создания прослушивания ивента при mount, делаем через watch, чтобы снизить потребление памяти (возможно)
    arrowFollow: function () {
      if (this.arrowFollow) {
        document.addEventListener("click", this.documentClick);
      } else {
        document.removeEventListener("click", this.documentClick);
      }
    },
    focused: function () {
      if (this.focused) {
        document.addEventListener("click", this.documentClick);
      } else {
        document.removeEventListener("click", this.documentClick);
      }
    },
  },
  beforeDestroy() {
    // Удаляем стрелку
    this.$emit("has_next", {
      id: this.info.id,
      circleX: this.circleX,
      circleY: this.circleY,
      circle_ref: this.$refs.circle,
      next_block: null,
    });
  },
};
</script>

<style lang="scss" scoped>
.button-element {
  position: relative;
}
.input-wrapper {
  border: 1px solid transparent;
  background: rgba(0, 111, 192, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 12px 5px 14px;
  min-height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  .text.textarea {
    width: 230px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.015em;
    color: white;
    /* OVERFLOW 2 ROWS DOTS   2 строки с точками в конце при оверфлове */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    -ms-line-clamp: 2;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    box-orient: vertical;
  }
  .input {
    &.textarea {
      resize: none;
      height: auto;
      width: 220px;
      &.hidden {
        opacity: 0;
        position: fixed;
        top: -100%;
      }
    }
    cursor: inherit;
    outline: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.015em;
    color: white;
  }
  .link {
    .input {
      font-weight: normal;
      letter-spacing: 0.015em;
      color: white;
    }
    .icon {
      margin-right: 5px;
      color: white;
    }
    .icon.focus {
      color: white;
      opacity: 0.87;
    }
  }
  .input::placeholder {
    opacity: 0.4;
    color: white;
  }
  &:hover {
    border: 1px solid rgba(0, 111, 192, 0.7);
    //  .circle-area .circle .filling{
    //     background: rgba(0, 111, 192, 0.7);
    //   }
  }
  &.highlight {
    border: 1px solid rgba(0, 111, 192, 0.7);
  }
  &.focus {
    border: 1px solid rgba(0, 111, 192, 0.7);
    cursor: default;
    .input {
      cursor: text;
    }
  }
  &.error {
    border: 1px solid rgba(218, 29, 29, 0.5);
  }
  &.action{
    border: 1px solid rgba(28, 28, 28, 0.7);
    background: #F5F5F5;
    .text.textarea {
      color: rgba(28, 28, 28, 0.8);
    }
    &:hover {
      border: 1px solid rgba(0, 111, 192, 0.7);
    }
  }
  // тип блока инлайн
  &.inline,
  &.phrases,
  &.feedback {
    background: transparent;
    border: 1px solid rgba(28, 28, 28, 0.3);
    .input {
      color: rgba(28, 28, 28, 0.7);
    }
    .text.textarea {
      color: rgba(28, 28, 28, 0.7);
    }
    .input::placeholder {
      opacity: 0.4;
      color: rgba(28, 28, 28, 0.7);
    }
    &:hover {
      border: 1px solid rgba(0, 111, 192, 0.7);
    }
    &.highlight {
      border: 1px solid rgba(0, 111, 192, 0.7);
    }
    &.focus {
      border: 1px solid rgba(0, 111, 192, 0.7);
      cursor: default;
      .input {
        cursor: text;
      }
    }
    .link {
      .input {
        width: 182px;
        font-weight: normal;
        letter-spacing: 0.015em;
        color: rgba(0, 111, 192, 0.7);
      }
      .icon {
        margin-right: 5px;
        color: rgba(0, 111, 192, 0.7);
        opacity: 0.87;
      }
      .icon.focus {
        color: rgba(0, 111, 192, 1);
        opacity: 1;
      }
    }
  }
  &.phrases {
    border-radius: 50px;
  }
  &.feedback {
    padding-bottom: 55px;
  }
}
.feedback-dropdown {
  width: 100%;
  height: 34px;
  position: absolute;
  bottom: 7px;
  left: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
// сообщ об ошибке
.error-message {
  margin-top: 7px;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.015em;
  color: rgba(218, 29, 29, 0.7) !important;
}
.symbol-count {
  position: absolute;
  font-size: 12px;
  line-height: 22px;
  color: white;
  opacity: 0.6;
  bottom: 0;
  right: 8px;
  &.inline {
    color: #1c1c1c;
  }
}
.circle {
  &-area {
    padding: 11px;
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
  }

  width: 14px;
  height: 14px;
  border-radius: 100%;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .filling {
    background: white;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    transition: background 0.3s ease-in-out;
    &.active {
      background: #006fc0;
    }
  }
  &.inline,
  &.phrases,
  &.feedback {
    border: 1px solid rgba(28, 28, 28, 0.3);
    .filling {
      background: rgba(28, 28, 28, 0.3);
    }
  }
  // &.highlight {
  //   & > .filling {
  //     background: rgba(0, 111, 192, 0.7);
  //   }
  // }
}
.btnMenu {
  background: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 9px 10px rgba(0, 0, 0, 0.14),
    0px 5px 14px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  &-wrapper {
    transition: visibility 0s ease, opacity 0.3s ease;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 0;
    right: -44px;
    padding: 4px;
    z-index: 9999;
    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  &__btn {
    opacity: 0.4;
  }
}
.arrow {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  #arrowhead {
    stroke: transparent;
    fill: #006fc0;
  }
}
</style>
